<template>
  <div
    v-if="laboratoryUser"
    class="pl-4 pt-4 pr-4">
    <b-row>
      <b-form-group
        :label="$t('user.login')"
        class="col-6 col-l">
        <b-form-input
          v-model="login"
          :disabled="!!laboratoryUser.id" />
      </b-form-group>
      <b-form-group class="col-3">
        <b-form-checkbox
          v-model="enabled"
          :disabled="isCurrentUser"
          class="form-checkbox">
          {{ $t('user.enabled') }}
        </b-form-checkbox>
      </b-form-group>
    </b-row>
    <b-row>
      <b-form-group
        :label="$t('user.lastname')"
        class="col-6 col-l">
        <b-form-input v-model="lastname" />
      </b-form-group>
      <b-form-group
        :label="$t('user.firstname')"
        class="col-6 col-r">
        <b-form-input v-model="firstname" />
      </b-form-group>
    </b-row>
    <b-row>
      <b-form-group
        :label="$t('user.email')"
        class="col-6 col-l">
        <b-form-input v-model="email" />
      </b-form-group>
    </b-row>
    <b-row>
      <b-form-group class="col-3">
        <b-form-checkbox
          v-model="admin"
          :disabled="!isAdminCheckBoxEnable">
          {{ $t('user.role.admin') }}
        </b-form-checkbox>
      </b-form-group>
      <b-form-group class="col-3">
        <b-form-checkbox
          v-model="doctor"
          :disabled="!isDoctorCheckBoxEnable">
          {{ $t('user.role.physician') }}
        </b-form-checkbox>
      </b-form-group>
    </b-row>
    <b-row
      class="align-end">
      <b-button
        ref="deleteButton"
        :disabled="!laboratoryUser.id || (!hasRole('ADMIN') && admin) || isCurrentUser"
        class="mt-4 mr-2 delete-button"
        variant="outline-danger"
        @click="showLaboratoryUserDeletionModal">
        {{ $t('common.delete') }}
      </b-button>
      <b-button
        ref="saveButton"
        class="mt-4"
        @click="save">
        {{ $t('save') }}
      </b-button>
      <app-confirmation-modal
        ref="laboratoryUserDeletionModal"
        :cancel-button-name="$t('common.no')"
        :message="$t('user.delete.message')"
        :ok-button-name="$t('common.yes')"
        :title="$t('user.delete.title')"
        header-text-variant="danger"
        ok-button-prevent-hiding
        ok-button-spinners-enabled
        ok-button-variant="outline-danger"
        @ok="deleteSelectedLaboratoryUser" />
    </b-row>
  </div>
</template>

<script>
import ConfirmationModal from '@/components/common/ConfirmationModal';
import NotificationMixin from '@/mixins/NotificationMixin';
import User from '@/models/User';
import {mapActions} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import SecurityMixin from '@/mixins/SecurityMixin';

export default {
  name: 'LaboratoryUser',
  components: {
    'app-confirmation-modal': ConfirmationModal
  },
  mixins: [
    NotificationMixin,
    SecurityMixin
  ],
  computed: {
    ...mapFields('laboratory', [
      'laboratoryUser',
      'laboratoryUser.id',
      'laboratoryUser.login',
      'laboratoryUser.lastname',
      'laboratoryUser.firstname',
      'laboratoryUser.email',
      'laboratoryUser.emailVerified',
      'laboratoryUser.enabled',
      'laboratoryUser.admin',
      'laboratoryUser.doctor',
      'laboratory'
    ]),
    ...mapFields('security', [
      'userInfo.laboratory.keycloakGroupId',
      'userInfo.laboratory.kitManufacturer'
    ]),
    isCurrentUser() {
      return this.userName() === this.login;
    },
    isDoctor() {
      return this.hasRole('WRITE_DIAGNOSTIC');
    },
    isAdmin() {
      return this.hasRole('ADMIN');
    },
    isManufacturerAdmin() {
      return this.isAdmin && (this.kitManufacturer ?? false);
    },
    isSameLaboratory() {
      return this.keycloakGroupId === this.laboratory.keycloakGroupId;
    },
    isSameLaboratoryAdmin() {
      return this.isSameLaboratory && this.isAdmin;
    },
    isAdminCheckBoxEnable() {
      return this.hasRole('SUPER_ADMIN') ||
          (!this.isCurrentUser && (this.isSameLaboratoryAdmin || this.isManufacturerAdmin));
    },
    isDoctorCheckBoxEnable() {
      return this.hasRole('SUPER_ADMIN') ||
          (!this.isCurrentUser && ((this.isSameLaboratory && (this.admin ? this.isAdmin : this.isDoctor)) || this.isManufacturerAdmin));
    }
  },
  methods: {
    ...mapActions('laboratory', [
      'createLaboratoryUser',
      'updateLaboratoryUser',
      'deleteLaboratoryUser'
    ]),
    showLaboratoryUserDeletionModal() {
      this.$refs.laboratoryUserDeletionModal.showModal();
    },
    save() {
      let promise;
      if (!this.laboratoryUser.id) {
        promise = this.createLaboratoryUser({
          id: this.laboratoryUser.id,
          laboratoryUser: User.parse(this.laboratoryUser)
        });
      } else {
        promise = this.updateLaboratoryUser({
          id: this.laboratoryUser.id,
          laboratoryUser: User.parse(this.laboratoryUser)
        });
      }
      promise
        .then(() => {
          this.showSuccessNotification(this.$t('user.update.notification.title'), this.$t('user.update.notification.success_message'));
          this.$emit('updated');
        })
        .catch(error => {
          this.showErrorNotification(this.$t('user.update.notification.title'), error);
        });
    },
    deleteSelectedLaboratoryUser() {
      this.deleteLaboratoryUser(this.laboratoryUser.id)
        .then(() => {
          this.showSuccessNotification(this.$t('user.delete.notification.title'), this.$t('user.delete.notification.success_message'));
          this.$emit('deleted');
        })
        .catch(error => {
          this.showErrorNotification(this.$t('user.delete.notification.title'), error);
        });
    }
  }
};
</script>