<template>
  <div v-if="laboratory"
       class="p-0 h-100">
    <div class="pl-4 pt-4 pr-4">
      <b-row>
        <h6>{{ $t('laboratory.name') }}</h6>
        <b-form-input v-model="name"
                      :disabled="!hasRole('ADMIN')"/>
      </b-row>
      <b-row
          class="align-end">
        <b-button
            ref="deleteButton"
            variant="outline-danger"
            class="mt-4 mr-2 delete-button"
            :disabled="!hasRole('ADMIN')"
            @click="showLaboratoryDeletionModal">
          {{ $t('common.delete') }}
        </b-button>
        <b-button
            ref="saveButton"
            class="mt-4"
            :disabled="!hasRole('ADMIN')"
            @click="save">
          {{ $t('save') }}
        </b-button>
        <app-confirmation-modal
            ref="laboratoryDeletionModal"
            :title="$t('laboratory.delete.title')"
            :message="$t('laboratory.delete.message')"
            :ok-button-name="$t('common.yes')"
            :ok-button-prevent-hiding="false"
            :prevent-automatic-hiding="false"
            :ok-button-spinners-enabled="true"
            ok-button-variant="outline-danger"
            :cancel-button-name="$t('common.no')"
            header-text-variant="danger"
            @ok="deleteSelectedLaboratory"/>
        <app-confirmation-modal
            ref="cantDeleteModal"
            :title="$t('laboratory.cant_delete.title')"
            :message="$t('laboratory.cant_delete.message')"
            :ok-button-name="$t('common.ok')"
            :ok-button-spinners-enabled="true"
            ok-button-variant="outline-danger"
            :cancel-button-enabled="false"
            header-text-variant="danger"
            @ok="$refs.cantDeleteModal.hideModal()"/>
      </b-row>
    </div>
    <div class="separator"/>
    <app-laboratory-users
        ref="appLaboratoryUsers"
        class="laboratory-users-sub-component"></app-laboratory-users>
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import ConfirmationModal from "@/components/common/ConfirmationModal";
import NotificationMixin from "@/mixins/NotificationMixin";
import {mapActions} from "vuex";
import Laboratory from "@/models/Laboratory";
import SecurityMixin from "@/mixins/SecurityMixin";
import LaboratoryUsers from "@/components/admin/laboratory/user/LaboratoryUsers";

export default {
  name: "Laboratory",
  components: {
    'app-confirmation-modal': ConfirmationModal,
    'app-laboratory-users': LaboratoryUsers
  },
  mixins: [
    NotificationMixin,
    SecurityMixin
  ],
  computed: {
    ...mapFields('laboratory', [
      'laboratory',
      'laboratory.name'
    ])
  },
  beforeMount() {
    if (!this.hasRole('SUPER_ADMIN')) {
      const laboratoryName = this.$store.getters['security/userLaboratoryName'];
      this.$store.dispatch('laboratory/findLaboratory', laboratoryName)
          .then(() => {
            this.$refs.appLaboratoryUsers.pageChange(1);
          })
          .catch(error => {
            this.$bvToast.toast(error.message, {
              title: this.$options.filters.capitalize(this.$t('error')),
              variant: 'danger',
              autoHideDelay: '3000',
              toaster: 'b-toaster-bottom-left'
            });
          });
    }
  },
  methods: {
    ...mapActions('laboratory', [
      'updateLaboratory',
      'deleteLaboratory'
    ]),
    showLaboratoryDeletionModal() {
      if (this.$store.getters['laboratory/hasUsers']) {
        this.$refs.cantDeleteModal.showModal();
        return;
      }
      this.$refs.laboratoryDeletionModal.showModal();
    },
    save() {
      this.updateLaboratory({id: this.laboratory.id, laboratory: Laboratory.parse(this.laboratory)})
          .then(() => {
            this.showSuccessNotification(this.$t('laboratory.update.notification.title'), this.$t('laboratory.update.notification.success_message'));
            this.$emit('updated');
          })
          .catch(error => {
            this.showErrorNotification(this.$t('laboratory.update.notification.title'), error);
          });
    },
    deleteSelectedLaboratory() {
      this.deleteLaboratory(this.laboratory.id)
          .then(() => {
            this.showSuccessNotification(this.$t('laboratory.delete.notification.title'), this.$t('laboratory.delete.notification.success_message'));
            this.$emit('deleted');
          })
          .catch(error => {
            this.showErrorNotification(this.$t('laboratory.delete.notification.title'), error);
          });
    }
  }
};
</script>