export default class Laboratory {
  constructor(id, name, keycloakGroupId, active, kitManufacturer) {
    this.id = id;
    this.name = name;
    this.keycloakGroupId = keycloakGroupId;
    this.active = active;
    this.kitManufacturer = kitManufacturer;
  }

  static build({ id, name, keycloakGroupId, active, kitManufacturer }) {
    return new Laboratory(id, name, keycloakGroupId, active, kitManufacturer);
  }

  static parse(value) {
    return value ? Laboratory.build({
      id: value.id,
      name: value.name,
      keycloakGroupId: value.keycloakGroupId,
      active: value.active,
      kitManufacturer: value.kitManufacturer
    }) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined || this.id !== '' ? this.id : null,
      name: this.name !== undefined || this.name !== '' ? this.name : null,
      keycloakGroupId: this.keycloakGroupId !== undefined || this.keycloakGroupId !== '' ? this.keycloakGroupId : null,
      active: this.active !== undefined ? this.active : null,
      kitManufacturer: this.kitManufacturer !== undefined ? this.kitManufacturer : null
    };
  }
}
