import { getField, updateField } from 'vuex-map-fields';
import cloneDeep from 'lodash/cloneDeep';
import { SmazentechApi } from '@/service/http-common';
import { findRun, getRunChart, getRunDiagnostic, getRunInterpretation } from '@/service/SmazentechService';
import Run from '@/models/Run';
import Well from '@/models/Well';
import { getSampleTypesForRunInterpretation } from '../../service/SmazentechService';

const state = {
  run: new Run(null,
    null,
    null,
    null,
    null,
    null,
    null,
    [],
    null,
    null,
    null,
    null,
    null,
    null,
    null),
  files: [],
  runFormStep: 1,
  availableLots: [],
  availableKits: [],
  availableSampleTypes: []
};

const getters = {
  getField,
  isRunInterpreted: state => !!state.run.interpretationRunByName,
  wells: state => state.run?.wells ? state.run?.wells : new Map(),
  anonymizedWells: state => Array.from(state.run?.wells?.values()).map(well => ({ ...well, id: null })) ?? [],
  getRunStatus: state => state.run.status,
  getFiles: state => state.files,
  commentByIdAndRun: state => commentId => state.run.comments.find(comment => comment.id === commentId),
  commentByIdAndWellId: state => (commentId, wellId) => {
    let comment = undefined;

    const well = Array.from(state.run?.wells?.values()).find(well => well.id === wellId);
    if (well) {
      comment = well.comments.find(comment => comment.id === commentId);
    }

    return comment;
  }
};

export const mutations = {
  updateField,
  setRun(state, { run, wellInterpretations, wellCharts, wellDiagnostics }) {
    state.run = run;
    if (wellInterpretations) {
      mutations.setWellInterpretations(state, { wellInterpretations });
    }
    if (wellCharts) {
      mutations.setWellCharts(state, { wellCharts });
    }
    if (wellDiagnostics) {
      mutations.setWellDiagnostics(state, { wellDiagnostics });
    }
  },
  setWells(state, { wells, applyUpdate }) {
    const updates = new Map(state.run.wells.entries());
    for (const well of wells) {
      if (state.run.wells.has(well.position)) {
        const clonedWell = Well.build(state.run.wells.get(well.position));
        applyUpdate(clonedWell, well);
        updates.set(well.position, clonedWell);
      }
    }
    state.run.wells = updates;
  },
  setWellsSampleType(state, { wells, sampleType }) {
    mutations.setWells(state, { wells, applyUpdate: well => well.sampleType = sampleType });
  },
  setWellsKit(state, { wells, kit }) {
    mutations.setWells(state, {
      wells, applyUpdate: well => {
        well.kit = kit;
        well.lot = null;
        if (!kit) {
          well.sampleType = undefined;
        }
      }
    });
  },
  setWellsLot(state, { wells, lot, kit }) {
    mutations.setWells(state, {
      wells, applyUpdate: well => {
        well.lot = lot;
        well.sampleType = lot ? state.availableSampleTypes.find(sampleType => sampleType?.diagnosticId === well?.sampleType?.diagnosticId
            && (lot.kitVersion.id === sampleType.kitVersionId || well?.sampleType?.type === 'SAMPLE')) : undefined;
        if (kit || !well.kit) {
          well.kit = kit ? kit : lot?.kitVersion?.kit;
        }
      }
    });
  },
  setWellsName(state, { wells, name }) {
    mutations.setWells(state, { wells, applyUpdate: well => well.name = name });
  },
  setWellInterpretations(state, { wellInterpretations }) {
    mutations.setWells(state, { wells: wellInterpretations, applyUpdate: (well, interpretation) => well.interpretation = interpretation });
  },
  setWellDiagnostics(state, { wellDiagnostics }) {
    mutations.setWells(state, { wells: wellDiagnostics, applyUpdate: (well, diagnostic) => well.diagnostic = diagnostic });
  },
  setWellCharts(state, { wellCharts }) {
    mutations.setWells(state, { wells: wellCharts, applyUpdate: (well, chart) => well.chart = chart });
  },
  setWellsComment(state, { wellComments }) {
    mutations.setWells(state, { wells: wellComments, applyUpdate: (well, { comments }) => well.comments = comments });
  },
  setPlateTemplate(state, { template, sampleTypesTemplate }) {
    const updates = new Map(state.run.wells.entries());
    for (const tpl of template.wellTemplates) {
      if (state.run.wells.has(tpl.position)) {
        const well = Well.parse(state.run.wells.get(tpl.position));
        const lotsSameVersion = state.availableLots.filter(lot => lot.kitVersion.id === tpl.kitVersion?.id);
        if (lotsSameVersion.length === 1) {
          const lot = lotsSameVersion[0];
          if (lot) {
            well.kit = lot.kitVersion.kit;
            well.lot = lot;
          } else if (state.availableKits.length === 1) {
            well.kit = state.availableKits[0];
            well.lot = null;
          } else {
            well.kit = null;
            well.lot = null;
          }
        }
        const sampleType = tpl.sampleType && state.availableSampleTypes.find(sampleType => {
          if (Number.isFinite(sampleType.controlId)) {
            return sampleType.controlId === tpl.sampleType.controlId;
          }

          return sampleType.kitVersionId === tpl.sampleType.kitVersionId && sampleType.type === tpl.sampleType.type;
        });
        if (sampleType && well.lot) {
          well.sampleType = sampleType;
        } else if (well?.lot?.kitVersion?.id && sampleTypesTemplate) {
          const sampleTypes = sampleTypesTemplate.filter(sampleType => sampleType?.kitVersionId === well?.lot?.kitVersion?.id);
          const sampleTypeVersion = sampleTypes.filter(sampleTypeVersion => sampleTypeVersion?.controlType === tpl?.sampleType?.controlType
                && sampleTypeVersion?.type === tpl?.sampleType?.type);

          if (sampleTypeVersion && sampleTypeVersion.length === 1) {
            well.sampleType = sampleTypeVersion[0];
          }
        }
        updates.set(tpl.position, well);
      }

    }
    state.run.wells = updates;
  },
  resetPlateTemplate(state) {
    const updates = new Map();
    for (const well of state.run.wells.values()) {
      const clonedWell = Well.build(well);
      clonedWell.kit = undefined;
      clonedWell.lot = undefined;
      clonedWell.sampleType = undefined;
      updates.set(well.position, clonedWell);
    }
    state.run.wells = updates;
  }
};

export const actions = {
  resetRunImportState({ commit }) {
    commit('updateField', { path: 'run', value: new Run() });

    return new Run();
  },
  findRun({ commit }, id) {
    return new Promise((resolve, reject) => {
      findRun(id)
        .then(run => {
          commit('updateField', { path: 'run', value: run });
          resolve(cloneDeep(run));
        })
        .catch(error => reject(error));
    });
  },
  findInterpretedRun({ commit }, id) {
    return new Promise((resolve, reject) => {
      id = +id;
      findRun(id)
        .then(run => Promise.all([
          getSampleTypesForRunInterpretation(id),
          getRunInterpretation(id),
          getRunChart(id),
          getRunDiagnostic(id)
        ]).then(([ wellSampleTypes, wellInterpretations, wellCharts, wellDiagnostics ]) => {
          commit('setRun', { run, wellInterpretations, wellCharts, wellDiagnostics });
          commit('updateField', { path: 'availableSampleTypes', value: wellSampleTypes });

          const response = {
            run: cloneDeep(run),
            wellSampleTypes: cloneDeep(wellSampleTypes),
            wellInterpretations: cloneDeep(wellInterpretations),
            wellCharts: cloneDeep(wellCharts),
            wellDiagnostics: cloneDeep(wellDiagnostics)
          };
          resolve(response);

          return Promise.resolve(response);
        }))
        .catch(error => reject(error));
    });
  },
  setCurrentRun({ commit }, run) {
    commit('updateField', { path: 'run', value: run });
  },
  setCurrentFiles({ commit }, files) {
    commit('updateField', { path: 'files', value: files });
  },
  setRunComments({ commit }, { comments }) {
    commit('updateField', { path: 'run.comments', value: comments });
  },
  setKitOptions({ state, commit }) {
    commit('updateField', { path: 'kitOptions', value: state.run.kitName });
    commit('updateField', { path: 'run.kitName', value: state.run.kitName ? state.run.kitName[0] : null });
  },
  putDirtyToFalse({ commit }) {
    commit('updateField', { path: 'run.dirty', value: false });
  },
  updateStatus(_, { id, status }) {
    return new Promise((resolve, reject) =>
      SmazentechApi.put(`run/${id}/status/?status=${status}`, null, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
        .then(response => {
          resolve(Run.parse(response.data));
        })
        .catch(error => {
          reject(error);
        })
    );
  },
  setWellInterpretations({ commit }, { wellInterpretations }) {
    commit('setWellInterpretations', { wellInterpretations });
  },
  setWellDiagnostics({ commit }, { wellDiagnostics }) {
    commit('setWellDiagnostics', { wellDiagnostics });
  },
  setWellCharts({ commit }, { wellCharts }) {
    commit('setWellCharts', { wellCharts });
  },
  setWellComments({ commit }, { wellComments }) {
    commit('setWellsComment', { wellComments });
  },
  updateWells({ commit }, { wells }) {
    commit('updateWells', { wells });
  },
  submitResults(_, { id }) {
    return new Promise((resolve, reject) =>
      SmazentechApi.put(`run/${id}/submit`, null, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
        .then(response => {
          resolve(Run.parse(response.data));
        })
        .catch(error => {
          reject(error);
        })
    );
  },
  setWellsSampleType({ commit }, { wells, sampleType }) {
    commit('setWellsSampleType', { wells, sampleType });
  },
  setWellsKit({ commit }, { wells, kit }) {
    commit('setWellsKit', { wells, kit });
  },
  setWellsLot({ commit }, { wells, lot }) {
    commit('setWellsLot', { wells, lot });
  },
  setWellsName({ commit }, { wells, name }) {
    commit('setWellsName', { wells, name });
  },
  setPlateTemplate({ commit }, { template, sampleTypesTemplate }) {
    commit('setPlateTemplate', { template, sampleTypesTemplate });
  },
  resetPlateTemplate({ commit }) {
    commit('resetPlateTemplate');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
